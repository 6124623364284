.news {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px;
}

.heading {
  width: 100%;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.heading h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  text-transform: capitalize;
}

.newsArticles {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.newsArticleList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.newsArticleListItem {
  width: 30%;
}

.newsArticleListItem a {
  text-decoration: none;
}

.newsImg {
  height: 220px;
  width: auto;
  overflow: hidden;
  text-align: center;
}

.newsImg img {
  height: 100%;
  width: auto;
  object-position: center;
}

.newsArticle {
  padding: 20px;
  border-right: 1px solid var(--grey-border-color);
  border-bottom: 1px solid var(--grey-border-color);
  border-left: 1px solid var(--grey-border-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

.newsCategory {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--grey-text-color);
  margin-bottom: 10px;
}

.newsTitle {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  margin-bottom: 10px;
}

.newsDesc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--light-grey-text-color);
}

@media (max-width: 1200px) {
  .container {
    padding: 100px 50px;
  }

  .newsImg {
    height: 180px;
    width: auto;
  }

  .newsArticleListItem {
    width: 31%;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 100px 30px;
  }

  .heading h3 {
    text-align: center;
  }

  .newsImg {
    height: 280px;
    overflow: hidden;
    text-align: center;
  }

  .newsImg img {
    height: 100%;
    width: auto;
    object-position: center;
  }

  .newsArticleList {
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(1, auto);
    gap: 50px;
    width: 500px;
    margin: 0 auto;
  }

  .newsArticleListItem {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .newsArticleList {
    width: 450px;
  }

  .newsImg {
    height: 245px;
  }
}

@media (max-width: 640px) {
  .newsArticleList {
    width: 375px;
  }

  .newsImg {
    height: 210px;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 100px 15px;
  }

  .newsArticleList {
    width: 325px;
  }

  .newsImg {
    height: 180px;
  }
}
