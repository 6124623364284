.achievements {
  padding: 100px 0;
  margin-bottom: 100px;
  background-color: var(--dark-red-orange-bg-text-color);
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
}

.container > h3 {
  font-size: 46px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-bottom: 40px;
  color: var(--white-text-color);
  text-align: center;
}

.container > p {
  width: 800px;
  margin: 0 auto 50px;
  text-align: center;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: var(--white-text-color);
}

.achievementContainer {
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: stretch;
  grid-template-columns: repeat(3, auto);
  gap: 30px;
  background-color: var(--yellow-text-btn-bg-color);
  padding: 50px;
  border-radius: 16px;
  margin: 0 auto;
}

.achievementContainer p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--dark-red-orange-bg-text-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.achievementContainer p > img {
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .container > h3 {
    font-size: 36px;
  }
  .achievementContainer p {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .container > h3 {
    font-size: 36px;
  }
  .container > p {
    width: 640px;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0;
  }
  .achievementContainer {
    width: 500px;
    justify-content: center;
    grid-template-columns: repeat(1, auto);
    gap: 50px;
  }
}

@media (max-width: 768px) {
  .container > p {
    width: 500px;
  }

  .achievementContainer {
    width: 400px;
  }
}

@media (max-width: 576px) {
  .container > h3 {
    font-size: 30px;
  }
  .container > p {
    width: 325px;
  }

  .achievementContainer {
    width: 325px;
  }
}
