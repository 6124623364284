.aboutUs {
  padding-top: 100px;
  margin-bottom: 100px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}

.container > h2 {
  font-size: 46px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
}

.container > p {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.7;
}
