.hero {
  background-color: var(--black-text-bg-color);
  height: 800px;
  width: 100%;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("../../../../resources/image/hero-bg-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  margin-bottom: 150px;
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.hexagons {
  position: absolute;
  bottom: 0;
  right: 150px;
}

.filledHexagon {
  position: absolute;
  bottom: -20px;
  right: 80px;
}
.hollowHexagon {
  position: absolute;
  bottom: -60px;
  right: 80px;
}

.heroImg {
  flex: 1;
  height: 900px;
  z-index: 4;
}

.heroTxt {
  flex: 1;
  height: 100%;
}

.heroTxt h1 {
  font-size: 76px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
}

.heroTxt h2 {
  font-size: 46px;
  font-weight: 600;
  color: var(--white-text-color);
  font-family: "Inter", sans-serif;
  margin-bottom: 30px;
}

.heroTxt p {
  font-size: 14px;
  font-weight: 400;
  color: var(--white-text-color);
  font-family: "Inter", sans-serif;
  width: 450px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.heroTxt button {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  border: none;
  background-color: var(--white-text-color);
  padding: 20px 70px;
  border-radius: 8px;
  border: 2px solid var(--white-text-color);
  transition: all 0.2s ease;
}

.heroTxt button:hover {
  cursor: pointer;
  border: 2px solid var(--white-text-color);
  background-color: transparent;
  color: var(--white-text-color);
}
.heroTxt button:active {
  scale: 0.98;
}

@media (max-width: 1200px) {
  .hero {
    height: 800px;
    background-position: 30% center;
  }

  .heroImg {
    height: 800px;
  }

  .heroTxt h1 {
    font-size: 66px;
  }

  .heroTxt h2 {
    font-size: 36px;
  }

  .heroTxt button {
    padding: 15px 50px;
    transition: all 0.2s ease;
  }
}

@media (max-width: 1024px) {
  .hero {
    height: 700px;
  }

  .heroImg {
    height: 700px;
  }
}

@media (max-width: 922px) {
  .hero {
    background-image: linear-gradient(
        to right top,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.75)
      ),
      url("../../../../resources/image/hero-bg-img.jpg");
  }
  .container {
    height: 100%;
  }
  .heroImg {
    display: none;
  }
  .heroTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .filledHexagon,
  .hollowHexagon {
    right: 50px;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 600px;
  }
}

@media (max-width: 576px) {
  .heroTxtContent h1 {
    font-size: 56px;
    margin-bottom: 10px;
  }

  .heroTxtContent h2 {
    font-size: 25px;
  }

  .heroTxtContent p {
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  .heroTxtContent button {
    padding: 20px 50px;
  }
  .filledHexagon,
  .hollowHexagon {
    right: 25px;
  }
}

@media (max-width: 425px) {
  .heroTxtContent h1 {
    font-size: 50px;
  }
}
