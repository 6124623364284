.about {
  width: 100%;
  margin-bottom: 100px;
}

.bubbleSvg {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
}

.linesSvg {
  position: absolute;
  bottom: -150px;
  left: 50px;
  z-index: 5;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.aboutEl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 365px;
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  position: relative;
}

.plusSvg {
  position: absolute;
  top: -55px;
  left: -55px;
}

.aboutEl:first-of-type {
  margin-bottom: 100px;
}

.aboutEl:last-of-type {
  height: 550px;
}

.aboutImg {
  flex: 1;
  height: 100%;
}

.img {
  height: 100%;
  width: auto;
}

.img1 {
  height: auto;
  width: 98%;
}

.aboutDescText {
  flex: 1;
}

.aboutDescText:first-of-type {
  padding-right: 64px;
}
.aboutDescText:last-of-type {
  padding-left: 25px;
}

.aboutDescText h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  margin-bottom: 10px;
}

.aboutDescText p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--charcoal-grey-text-color);
  line-height: 2;
  margin-bottom: 10px;
}

.aboutBtn {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  padding: 20px 40px;
  background-color: var(--yellow-text-btn-bg-color);
  border: 2px solid var(--yellow-text-btn-bg-color);
  border-radius: 12px;
  transition: all 0.25s ease;
}

.aboutBtn:hover {
  cursor: pointer;
  background-color: transparent;
}

.aboutBtn:active {
  scale: 0.98;
}

@media (max-width: 1300px) {
  .aboutEl {
    width: 1000px;
  }

  .aboutDescText h3 {
    font-size: 36px;
  }

  .aboutDescText:last-of-type {
    padding-left: 50px;
  }

  .img1 {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 1200px) {
  .aboutEl {
    width: 900px;
  }

  .plusSvg {
    top: -20px;
  }

  .aboutEl:last-of-type {
    height: 450px;
  }

  .aboutImg {
    flex: none;
  }

  .aboutImg1 {
    height: auto;
    width: 400px;
  }

  .img1 {
    height: auto;
    width: 400px;
  }

  .aboutImg2 {
    flex: 0.8;
  }

  .aboutDescText:first-of-type {
    padding-right: 0px;
  }
}

@media (max-width: 1100px) {
  .aboutEl {
    flex-direction: column;
    height: auto;
  }
  .aboutEl:first-of-type {
    margin-bottom: 50px;
  }
  .aboutEl:last-of-type {
    flex-direction: column-reverse;
    height: auto;
  }
  .plusSvg {
    top: -55px;
    left: 195px;
  }
  .aboutImg1 {
    margin-bottom: 50px;
  }
  .aboutImg2 {
    height: 450px;
    width: 450px;
  }
  .img2 {
    height: auto;
    width: 100%;
  }
  .aboutDescText {
    text-align: center;
  }
  .aboutDescText h3,
  .aboutDescText p {
    margin-bottom: 25px;
  }
  .aboutDescText:last-of-type {
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .aboutEl {
    width: 768px;
  }
  .aboutDescText {
    width: 700px;
  }
  .plusSvg {
    left: 130px;
  }
}

@media (max-width: 768px) {
  .aboutEl {
    width: 600px;
  }
  .aboutDescText {
    width: 576px;
  }
  .plusSvg {
    left: 45px;
  }
  .bubbleSvg {
    height: 100px;
    width: auto;
    right: 25px;
  }
  .linesSvg {
    left: 25px;
  }
}

@media (max-width: 640px) {
  .aboutEl {
    width: 500px;
  }
  .aboutDescText {
    width: 425px;
  }
  .plusSvg {
    left: -5px;
  }
}

@media (max-width: 576px) {
  .aboutEl {
    width: 425px;
  }
  .aboutDescText {
    width: 375px;
  }
  .aboutImg1,
  .aboutImg2 {
    width: 350px;
    overflow: hidden;
  }
  .plusSvg {
    left: 0px;
    top: -40px;
    height: 50px;
    width: auto;
  }
  .aboutDescText h3 {
    font-size: 30px;
  }
}

@media (max-width: 425px) {
  .aboutEl {
    width: 375px;
  }
  .aboutDescText {
    width: 325px;
  }
  .aboutImg1,
  .aboutImg2 {
    width: 325px;
  }
  .aboutImg1 {
    width: 300px;
  }
  .plusSvg {
    left: 5px;
    top: -33px;
    height: 45px;
    width: auto;
  }
}
