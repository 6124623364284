.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 

general breakpoints for media queries

425, 576, 600, 768, 992, 1024, 1200, 1440

*/
