.section {
  background-color: var(--dark-red-orange-bg-text-color);
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 160px;
}

.bubblesBigSvg {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  right: 20px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.heading h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  margin-bottom: 20px;
  text-transform: capitalize;
}

.heading p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  width: 455px;
  text-align: center;
  line-height: 1.6;
}

.services {
  background-color: var(--yellow-text-btn-bg-color);
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  padding-bottom: 0px;
  width: 100%;
}

.servicesList {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.servicesListItem {
  width: 30%;
  margin-bottom: 60px;
}

.servicesListItem img {
  margin-bottom: 10px;
}

.servicesListItem h4 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: var(--dark-nero-text-color);
  margin-bottom: 10px;
}

.servicesListItem p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--dark-nero-text-color);
}

.liImg {
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .services {
    padding: 50px;
  }

  .servicesList {
    display: grid;
    justify-content: space-between;
    align-content: space-between;
    grid-template-columns: repeat(2, auto);
    gap: 40px;
  }

  .servicesListItem {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 80px 120px;
  }

  .heading h3 {
    font-size: 36px;
  }

  .heading p {
    width: 350px;
  }

  .services {
    padding: 30px;
  }

  .servicesList {
    gap: 30px;
  }
}

@media (max-width: 850px) {
  .services {
    padding: 50px;
  }

  .servicesList {
    grid-template-columns: repeat(1, auto);
    gap: 40px;
  }

  .servicesListItem {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 80px 40px 120px;
  }

  .heading h3 {
    font-size: 30px;
  }

  .heading p {
    width: 250px;
  }
}
