.section {
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 25px;
}

.sectionTitle {
  font-size: 46px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: var(--dark-red-orange-bg-text-color);
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.horizontalRule {
  width: 200px;
  height: 5px;
  background-color: var(--yellow-text-btn-bg-color);
  margin: 0 auto 50px;
}

.categoryContainer {
  margin: 0 auto 50px;
}

.categoryList {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
}

/* .category{} */

.categoryBtn {
  border: none;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--dark-red-orange-bg-text-color);
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  transition: all 0.2s ease;
}
.categoryBtn:hover {
  cursor: pointer;
  background-color: var(--dark-red-orange-bg-text-color);
  color: #fff;
}
.categoryBtn:active {
  transform: scale(0.95);
}

.categoryBtn.active {
  background-color: var(--dark-red-orange-bg-text-color);
  color: #fff;
}

.contentContainer {
  margin: 0 auto;
}

.contentList {
  /* display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-template-rows: repeat(auto, auto);
  justify-content: center;
  align-items: baseline;
  grid-column-gap: 30px;
  grid-row-gap: 30px; */
  columns: 3;
  column-gap: 30px;
  /* row-gap: 30px; */
}
.contentEl {
  /* width: 460px; */
  /* width: calc((100% - 60px) / 3); */
  width: calc((100% - 60) / 3);
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  break-inside: avoid;
  margin-bottom: 30px;
}
.contentImgTxt {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-self: center;
}
.contentImg {
  width: 100%;
  height: auto;
}

.textBox {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}

.contentText {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #fff;
}

.contentEl:hover .textBox {
  bottom: 0px;
}

@media (max-width: 992px) {
  .sectionTitle {
    font-size: 36px;
  }
  .horizontalRule {
    width: 150px;
    height: 3px;
  }
  .categoryBtn {
    font-size: 16px;
    font-weight: 300;
  }
  .contentList {
    columns: 2;
    column-gap: 30px;
  }
  .contentEl {
    /* width: calc((100% - 60px) / 2); */
    width: calc((100% - 60) / 2);
  }
}

@media (max-width: 768px) {
  .category {
    width: 150px;
  }
  .categoryList {
    width: 500px;
    justify-content: space-evenly;
  }
  .categoryBtn {
    width: 100%;
    font-size: 15px;
    padding: 7px 0px;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .sectionTitle {
    font-size: 30px;
  }
  .horizontalRule {
    width: 100px;
    height: 2px;
  }
  .categoryList {
    width: 375px;
  }
  .category {
    width: 130px;
  }
  .categoryBtn {
    font-size: 14px;
  }
  .contentList {
    columns: 1;
  }
  .contentEl {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 425px) {
  .sectionTitle {
    font-size: 24px;
  }
  .categoryList {
    width: 325px;
  }
  .contentEl {
    width: 325px;
  }
}
