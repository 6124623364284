.hero {
  width: 100%;
}

.title {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("https://images.unsplash.com/photo-1587560699334-bea93391dcef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  /* url("https://images.unsplash.com/uploads/1413222992504f1b734a6/1928e537?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.pageName {
  font-size: 24px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-transform: uppercase;
  letter-spacing: 10px;
  margin-bottom: 30px;
}

.headingTitle {
  font-size: 46px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  margin: 0 auto 50px;
}

.headingDesc {
  width: 850px;
  font-size: 36px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-align: center;
  line-height: 1.5;
}

.summary {
  width: 100%;
  height: 800px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://images.unsplash.com/photo-1559030623-0226b1241edd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.summaryText {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.summaryText > p {
  width: 900px;
  font-size: 36px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-align: center;
  line-height: 1.5;
}

.emailLink > a {
  color: var(--yellow-text-btn-bg-color);
  text-decoration: none;
}

.form {
  padding: 100px 0;
  background-color: var(--dark-red-orange-bg-text-color);
}

.contactTitle {
  font-size: 36px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: var(--white-text-color);
  margin-bottom: 80px;
  width: 600px;
  text-align: center;
}

form {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* border: 1px solid red; */
}

.names,
.contacts,
.comments {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 40px;
}

.names > div,
.contacts > div,
.comments > div {
  width: 100%;
}

.names label,
.contacts label,
.comments label {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(--black-text-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--white-text-color);
}

.names label > span,
.contacts label > span,
.comments label > span {
  margin-bottom: 15px;
}

.names label > input,
.contacts label > input,
.comments label > input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  font-size: 18px;
  border: 1px solid var(--light-grey-text-color);
  /* border-radius: 4px; */
}

.names label > input::placeholder,
.contacts label > input::placeholder,
.comments label > input::placeholder {
  color: var(--light-grey-text-color);
}

.comments {
  margin-bottom: 100px;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(--black-text-bg-color);
}

input:focus,
textarea:focus {
  outline: none;
}

.submitBtn > button {
  padding: 15px 50px;
  border: 1px solid var(--yellow-text-btn-bg-color);
  border-radius: 5px;
  background-color: var(--yellow-text-btn-bg-color);
  color: var(--dark-red-orange-bg-text-color);
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}
.submitBtn > button:hover {
  cursor: pointer;
  background-color: transparent;
  color: var(--yellow-text-btn-bg-color);
}
.submitBtn > button:active {
  transform: scale(0.98);
}

@media (max-width: 1300px) {
  .headingTitle {
    font-size: 36px;
  }
}

@media (max-width: 1200px) {
  .title,
  .summary {
    height: 700px;
  }
  .headingDesc,
  .summaryText > p {
    width: 700px;
    font-size: 26px;
  }
}

@media (max-width: 900px) {
  .title,
  .summary {
    height: 600px;
  }
  .headingTitle {
    font-size: 36px;
  }
  .headingDesc,
  .summaryText > p {
    width: 600px;
    font-size: 18px;
  }
  .contactTitle {
    font-size: 30px;
    width: 500px;
  }
  form {
    width: 600px;
  }
  .comments {
    margin-bottom: 60px;
  }
}

@media (max-width: 700px) {
  .headingDesc,
  .summaryText > p {
    width: 425px;
    line-height: 1.8;
  }
  form {
    width: 550px;
  }
  form * {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .contactTitle {
    font-size: 24px;
    width: 400px;
  }
  form {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .pageName {
    font-size: 18px;
  }
  .headingTitle {
    font-size: 30px;
  }
  .headingDesc,
  .summaryText > p {
    width: 325px;
    font-size: 18px;
  }
  form {
    width: 375px;
  }
  .names,
  .contacts {
    flex-direction: column;
  }
}

@media (max-width: 475px) {
  .pageName {
    font-size: 16px;
  }
  .headingTitle {
    line-height: 1.5;
  }
  .headingTitle > span {
    display: block;
    text-align: center;
  }
  .headingDesc,
  .summaryText > p {
    width: 325px;
    font-size: 16px;
  }
  .contactTitle {
    font-size: 24px;
    width: 325px;
    font-weight: 500;
  }
  form {
    width: 325px;
  }
}

/*

.about {
  margin-bottom: 100px;
}

.aboutDesc {
  width: 1130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.aboutDesc:nth-of-type(2n) {
  flex-direction: row-reverse;
}
.aboutDesc:not(:last-of-type) {
  margin-bottom: 50px;
}

.aboutImg {
  width: 48%;
  overflow: hidden;
}
.aboutImg > img {
  width: 100%;
  height: auto;
}

.aboutText {
  width: 48%;
  padding: 30px 0;
}

.aboutText > h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  margin-bottom: 30px;
}

.aboutText > p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  line-height: 1.6;
}

*/
