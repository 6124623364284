.hero {
  width: 100%;
}

.title {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://images.unsplash.com/photo-1555421689-d68471e189f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  margin-bottom: 100px;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  height: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.pageName {
  font-size: 24px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-transform: uppercase;
  letter-spacing: 10px;
  margin-bottom: 30px;
}

.headingTitle {
  font-size: 46px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  margin: 0 auto 50px;
}

.headingDesc {
  width: 850px;
  font-size: 36px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-align: center;
  line-height: 1.5;
}

.about {
  margin-bottom: 100px;
}

.aboutDesc {
  width: 1130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.aboutDesc:nth-of-type(2n) {
  flex-direction: row-reverse;
}
.aboutDesc:not(:last-of-type) {
  margin-bottom: 50px;
}

.aboutImg {
  width: 48%;
  overflow: hidden;
}
.aboutImg > img {
  width: 100%;
  height: auto;
}

.aboutText {
  width: 48%;
  padding: 30px 0;
}

.aboutText > h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  margin-bottom: 30px;
}

.aboutText > p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  line-height: 1.6;
}

.summary {
  width: 100%;
  height: 800px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.summaryText {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.summaryText > p {
  width: 900px;
  font-size: 36px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  text-align: center;
  line-height: 1.5;
}

@media (max-width: 1300px) {
  .headingTitle {
    font-size: 36px;
  }
  .aboutDesc {
    width: 1000px;
  }

  .aboutText > h3 {
    font-size: 36px;
  }
}

@media (max-width: 1200px) {
  .title,
  .summary {
    height: 700px;
  }
  .headingDesc,
  .summaryText > p {
    width: 700px;
    font-size: 26px;
  }
  .aboutDesc {
    width: 900px;
    flex-direction: column;
    justify-content: center;
  }
  .aboutDesc:nth-of-type(2n) {
    flex-direction: column;
  }

  .aboutImg {
    margin: 0 auto;
    width: 600px;
  }
  .aboutImg > img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .aboutText {
    width: 750px;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 900px) {
  .title,
  .summary {
    height: 600px;
  }
  .headingTitle {
    font-size: 36px;
  }
  .headingDesc,
  .summaryText > p {
    width: 600px;
    font-size: 18px;
  }
  .aboutDesc {
    width: 640px;
  }
  .aboutImg {
    width: 576px;
  }
  .aboutText {
    width: 600px;
  }
}

@media (max-width: 700px) {
  .headingDesc,
  .summaryText > p {
    width: 425px;
    line-height: 1.8;
  }
  .aboutDesc {
    width: 500px;
  }
  .aboutImg {
    width: 425px;
  }
  .aboutText {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .pageName {
    font-size: 18px;
  }
  .headingTitle {
    font-size: 30px;
  }
  .headingDesc,
  .summaryText > p {
    width: 325px;
    font-size: 18px;
  }
  .aboutDesc {
    width: 425px;
  }
  .aboutImg {
    width: 325px;
  }
  .aboutText {
    width: 425px;
  }
  .aboutText > h3 {
    font-size: 30px;
  }
}

@media (max-width: 475px) {
  .pageName {
    font-size: 16px;
  }
  .headingTitle {
    line-height: 1.5;
  }
  .headingTitle > span {
    display: block;
    text-align: center;
  }
  .headingDesc,
  .summaryText > p {
    width: 325px;
    font-size: 16px;
  }
  .aboutDesc {
    width: 325px;
  }
  .aboutText {
    width: 325px;
  }
}
