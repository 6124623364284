:root {
  --yellow-text-btn-bg-color: #fbc157;
  --black-text-bg-color: #000000;
  --dark-red-orange-bg-text-color: #1e1511;
  --dark-nero-text-color: #161515;
  --white-text-color: #ffffff;
  --grey-text-color: #777777;
  --charcoal-grey-text-color: #454545;
  --light-grey-text-color: #5f6276;
  --grey-border-color: #ededed;
  --grey-footer-text: #898888;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--primary-white-bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 

375, 425, 768, 1024, 1440

576, 768, 992, 1200

600, 768, 992, 1200

768, 1024, 1216, 1408

425, 576, 600, 768, 992, 1024, 1200, 1440


*/
