.header {
  height: 90px;
  width: 100%;
  z-index: 10;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
}

.header.fix {
  position: fixed;
  height: 90px;
  width: 100%;
  z-index: 10;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.logo__link {
  text-decoration: none;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoImg {
  height: auto;
}
