.footer {
  width: 100%;
  background-color: var(--dark-red-orange-bg-text-color);
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.logoSocial {
  width: 25%;
  margin-right: 80px;
}

.logoSocial img {
  margin-bottom: 10px;
}

.logoSocial p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--grey-footer-text);
  margin-bottom: 20px;
  line-height: 2;
}

.socialLink:not(:last-of-type) {
  margin-right: 20px;
}

.detailsLinks {
  flex: 1;
}

ul {
  list-style-type: none;
}

.detailsLinksList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.detailsLinksListItem h5 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
  margin-bottom: 20px;
}

.linksListItem {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.linksListItem:not(:last-of-type) {
  margin-bottom: 22px;
}

.linksListItem .link {
  text-decoration: none;
  color: var(--grey-footer-text);
}

.copyrightText {
  text-align: center;
}

.copyrightText h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--white-text-color);
}

.copyrightText h5 span {
  color: var(--yellow-text-btn-bg-color);
}

@media (max-width: 1200px) {
  .container {
    padding: 80px 50px;
  }
}

@media (max-width: 1024px) {
  .logoSocial {
    margin-right: 40px;
  }
}

@media (max-width: 992px) {
  /* .logoSocial {
    width: 30%;
  } */
  .detailsLinksList {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, auto);
    gap: 50px;
  }

  .detailsLinksListItem {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .companyDesc {
    text-align: center;
  }

  .logoSocial {
    text-align: center;
    width: 350px;
    margin-bottom: 50px;
    margin-right: 0;
  }
  .footerLogo {
    margin: 0 auto;
  }

  .detailsLinksList {
    justify-content: space-around;
    width: 450px;
  }

  .detailsLinksListItem {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .logoSocial {
    width: 325px;
  }

  .detailsLinksList {
    grid-template-columns: repeat(1, auto);
    width: 375px;
  }
}
