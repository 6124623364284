.vision {
  margin-bottom: 100px;
}

.container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.container > h3 {
  font-size: 46px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--black-text-bg-color);
}

.container > p {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

.visionDesc {
  width: 1130px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.visionImg {
  width: 48%;
  height: 100%;
  overflow: hidden;
}
.visionImg > img {
  height: auto;
  width: 100%;
}

.visionText {
  width: 48%;
  padding: 30px 0;
}

.visionText > h3 {
  font-size: 46px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  margin-bottom: 30px;
}

.visionText > p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--black-text-bg-color);
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .visionDesc {
    width: 900px;
    flex-direction: column;
    justify-content: center;
  }

  .visionImg {
    margin: 0 auto;
    width: 600px;
  }
  .visionImg > img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .visionText {
    width: 750px;
    margin: 0 auto;
    text-align: center;
  }
  .visionText > h3 {
    font-size: 36px;
  }
}

@media (max-width: 900px) {
  .visionDesc {
    width: 640px;
  }
  .visionImg {
    width: 576px;
  }
  .visionText {
    width: 600px;
  }
}

@media (max-width: 700px) {
  .visionDesc {
    width: 500px;
  }
  .visionImg {
    width: 425px;
  }
  .visionText {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .visionDesc {
    width: 425px;
  }
  .visionImg {
    width: 325px;
  }
  .visionText {
    width: 425px;
  }
  .visionText > h3 {
    font-size: 30px;
  }
}

@media (max-width: 475px) {
  .visionDesc {
    width: 325px;
  }
  .visionText {
    width: 325px;
  }
}
